@import "variables.scss";
body {
    font-family: $font-biryani;
    color: $color-grey-a;
    font-weight: 300;
    font-size: $font-16;
    line-height: 1.75;
}
body,
html {
    height: 100%;
}
a {
    @include transition;
    @include appearance;
    text-decoration: none;
    outline: none;
    color: $color-light-blue;
    font-size: $font-14;
}
a:hover,a:focus,a:active{
    text-decoration: none;
    outline: none;
    color: $color-blue;
}
img{
    max-width: 100%;
    height: auto;
}
h1, .h1 {
    font-size: $font-48;
    font-family: $font-nanum;
    line-height: 1.14;
    font-weight: normal;
    color: $color-grey-a;
}
h2, .h2 {
    font-size: $font-36;
    font-family: $font-nanum;
    line-height: 1.14;
    font-weight: normal;
    color: $color-grey-a;
}
h3, .h3 {
    font-size: $font-20;
    font-family: $font-biryani;
    line-height: 1.75;
    font-weight: bold;
    color: $color-grey-a;
}
h4, .h4 {
    font-size: $font-18;
    font-family: $font-biryani;
    line-height: 1.75;
    font-weight: normal;
    color: $color-grey-a;
}
h5, .h5 {
    font-size: $font-14;
    font-family: $font-biryani;
    line-height: 1.75;
    font-weight: normal;
    color: $color-grey-a;
}
h6, .h6 {
    font-size: $font-16;
    font-family: $font-nanum;
    line-height: 1.75;
    font-weight: normal;
    color: $color-half-white;
}
section {
    width: 100%;
    padding: 6.25rem 0;
    position: relative;
    clear: both;
}
div.middle {
    display: table;
    height: 100%;
    z-index: 10;
    position: relative;
    & > div {
        vertical-align: middle;
        display: table-cell;
        height: 100%;
    }
    & > div.bottom {
        vertical-align: bottom;
    }
}
.btn {
    background: $color-yellow;
    @include border(6x);
    border: 0;
    padding: 0.75rem 1.25rem;
    color: #fff;
    @include transition;
    i {
        @include transition;
        margin-left: 0.3125rem;
    }
    &:hover, &:active {
        @include scale(1.02);
        background: $color-blue;
        color: #fff;
        i {
            @include translateX(3px);
        }
    }
    img {
        max-width: 1.3125rem;
    }
}
@media (min-width: 1440px) {
    .container {
        max-width: 76.25rem;
    }
}
._splash {
    background: $color-black url("../images/splash-bg.png") no-repeat center fixed;
    background-size: cover;
    ._splashSection {
        padding: 1.87rem 0;
        ._txt {
            margin-bottom: 2.81rem;
            h1 {
                color: $color-yellow;
                margin-bottom: 1.18rem;
            }
        }
        ._holder {
            img {
                margin-bottom: 1rem;
            }
            h2 {
                margin-bottom: 0.87rem;
            }
            a {
                &:hover {
                    color: #fff;
                }
            }
            .btn {
                width: 100%;
                max-width: 15.12rem;
            }
        }
    }
}
@media (min-height: 1024px) {
    ._h100 {
        height: 100%;
    }
}
._topBar {
    position: relative;
    background: $color-half-white;
    padding: 0.75rem 0;
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
            color: $color-grey-e;
            font-size: 0.875rem;
            position: relative;
            padding-left: 1.25rem;
            br {
                display: none;
            }
            img {
                max-height: 0.875rem;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 3px;
            }
            a {
                color: $color-grey-e;
                font-weight: normal;
            }
        }
    }
}
.navbar {
    background: $color-white;
    padding-top: 1.312rem;
    padding-bottom: 1.312rem;
    z-index: 999;
    &._shadow {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    }
    .navbar-nav {
        &>li {
            a {
                font-weight: normal;
                font-size: 1rem;
                color: $color-grey-e !important;
                &:hover {
                    color: $color-light-blue !important;
                }
            }
            &.menu-item-has-children {
                position: relative;
                &>a {
                    position: relative;
                    &:after {
                        content: "\f107";
                        font-family: FontAwesome;
                        margin-left: 3px;
                        @include transition;
                    }
                }
                @media (min-width: 992px) {
                    &:hover > .sub-menu {
                        display: block;
                    }
                }
                ul.sub-menu {
                    position: absolute;
                    left: 0;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
                    top: 100%;
                    background: #fff;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    min-width: 18.75rem;
                    display: none;
                    li {
                        a {
                            display: inline-block;
                            width: 100%;
                            padding: 0.625rem 1.25rem;
                        }
                    }
                    .menu-item-has-children {
                        position: relative;
                        @media (min-width: 992px) {
                            &:hover > .sub-menu {
                                display: block;
                            }
                        }
                        .sub-menu {
                            left: 100%;
                            top: 0;
                        }
                        &>a {
                            position: relative;
                            &:after {
                                content: "\f105";
                                font-family: FontAwesome;
                                margin-left: 3px;
                                @include transition;
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .navbar-nav {
            &>li {
                margin-right: 2.125rem;
            }
        }
    }
}
.is-sticky {
    .navbar {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    }
}
@media (min-width: 992px) {
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
        .navbar-nav > li {
            a {
                padding-top: 1.9rem;
                padding-bottom: 1.9rem;
            }
        }
    }
}
iframe {
    max-width: 100%;
}
footer {
    background: $color-blue;
    padding-top: 5.135rem;
    padding-bottom: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
    a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: normal;
        &:hover {
            color: $color-white;
        }
    }
    p {
        font-size: 0.87rem;
    }
    ._logo {
        text-align: center;
        margin-bottom: 6.25rem;
        img {
            margin-bottom: 1.125rem;
        }
        ._social {
            a {
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                line-height: 1.75rem;
                color: $color-blue;
                background: $color-white;
                @include border-50;
                margin: 0 0.625rem;
                &:hover {
                    @include scale(1.08);
                }
            }
        }
    }
    ._widget {
        margin-bottom: 6.25rem;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                font-size: 0.875rem;
                line-height: 2.25rem;
                a {
                    color: rgba(255, 255, 255, 0.6);
                    font-weight: normal;
                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
        h6 {
            text-transform: uppercase;
            opacity: 0.6;
            margin-bottom: 1.25rem;
        }
        h5 {
            color: rgba(255, 255, 255, 0.6);
        }
    }
    ._copy {
        color: rgba(255, 255, 255, 0.6);
        p {
            margin: 0;
            font-size: 0.875rem;
            font-family: $font-robot;
        }
    }
}
._link {
    font-weight: bold;
    display: inline-block;
    i {
        margin-left: 0.3125rem;
        @include transition;
    }
    &:hover {
        & > i {
            @include translateX(5px);
        }
    }
    &._scrollTo {
        &:hover {
            color: $color-white;
            & > i {
                @include translateY(5px);
            }
        }
    }
}
._banner {
    background: $color-blue;
    color: $color-white;
    h1 {
        margin-bottom: 2rem;
    }
    p {
        margin-bottom: 3rem;
    }
    ._link {
        &:hover {
            color: $color-white;
        }
    }
}
.gutter-40 {
    margin-left: -20px;
    margin-right: -20px;
    div[class^="col-"], div[class*=" col-"] {
        padding-left: 20px;
        padding-right: 20px;
    }
}
.gutter-60 {
    margin-left: -30px;
    margin-right: -30px;
    div[class^="col-"], div[class*=" col-"] {
        padding-left: 30px;
        padding-right: 30px;
    }
}
._area {
    padding: 5.25rem 1.875rem 6.68rem 1.875rem;
    h2 {
        margin-bottom: 1.56rem;
    }
    ._max {
        max-width: 36.25rem;
        width: 100%;
        margin: 0 auto 5.18rem auto;
    }
}
._service {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 2.5rem;
    img {
        height: 19.43rem;
        object-position: center;
        object-fit: cover;
        width: 100%;
        @include transition-d(2s);
    }
    &:hover {
        img {
            @include scale(1.2);
        }
    }
    span {
        position: absolute;
        left: 1.25rem;
        top: 1.25rem;
        z-index: 9;
        color: $color-white;
        font-family: $font-nanum;
        font-size: 1.125rem;
        font-weight: normal;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    }
}
._hands {
    background: $color-half-white;
}
._value {
    position: relative;
    img {
        margin-bottom: 1.125rem;
    }
}
._testimonials {
    ._top {
        margin-bottom: 5.62rem;
        h2 {
            margin-bottom: 1.56rem;
        }
        ._txt {
            max-width: 36.25rem;
            width: 100%;
            margin: 0 auto;
            p {
                margin-bottom: 1.37rem;
            }
            div {
                font-size: $font-14;
            }
        }
    }
    ._bottom {
        flex-wrap: wrap;
        & > div {
           width: 20%;
        }
    }
}
.carousel-indicators {
    margin-bottom: 0;
    bottom: -20px;
    li {
        width: 0.5rem;
        height: 0.5rem;
        @include border-50;
        background: $color-grey-e;
        border: 0;
        &.active {
            background: $color-yellow;
            opacity: 1;
        }
    }
}
._blog {
    h2 {
        margin-bottom: 4.18rem;
    }
}
._post {
    display: inline-block;
    width: 100%;
    color: $color-grey-a;
    margin-bottom: 3.12rem;
    h5 {
        font-size: 1rem;
    }
    p {

    }
}
._iForm {
   background: $color-half-white;
    h2 {
        margin-bottom: 1.62rem;
    }
    ._img {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    ._form {
        padding-left: 11.59vw;
        padding-right: 6.94vw;
    }
}
._smallBanner {
    padding: 0;
    background: $color-blue;
    color: $color-white;
    ._txt {
        padding: 11.23vh 8.68vw;
        h2 {
            margin-bottom: 0.93rem;
        }
        p {
            margin: 0;
            font-size: 0.875rem;
        }
    }
    ._img {
        width: 100%;
        position: relative;
        height: 100%;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(2,29,73,0.8);
        }
        & > img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
        ._logo {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            @include translateY(-50%);
            z-index: 9;
            img {
                max-width: 17.5rem;
            }
        }
    }
}
._txtBlock {
    padding: 5rem 0;
    p {
        margin-bottom: 2.5rem;
        & + p {
            margin-top: 1.875rem;
        }
    }
    ._max {
        max-width: 48.43rem;
        width: 100%;
        margin: 0 auto;
    }
    h5 {
        font-size: 1rem;
        font-weight: bold;
    }
    h3 {
        font-size: 1.5rem;
        font-family: $font-nanum;
        margin-bottom: 0.75rem;
        font-weight: normal;
    }
    blockquote {
        position: relative;
        padding-left: 1.56rem;
        margin-left: 2.81rem;
        font-weight: bold;
        border-left: 4px solid $color-yellow;
        margin-bottom: 2.5rem;
    }
    img {
        margin-bottom: 1.875rem;
    }
}
._cta {
    padding: 0;
    background: $color-half-white;
    ._txt {
        padding: 2vh 7.29vw 2vh 11.66vw;
        h2 {
            margin-bottom: 1.31rem;
        }
        p {
            margin-bottom: 1.43rem;
        }
    }
    ._img {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }
}
._abBanner {
    padding: 0;
    background: $color-half-white;
    &._adj {
        ._txt {
            padding-top: 10.93rem;
            padding-bottom: 8.125rem;
        }
    }
    ._txt {
        padding: 2vh 6.45vw 2vh 8.68vw;
        h1 {
            font-size: 3.75rem;
            margin: 0;
            &._adj {
                margin-bottom: 2rem;
            }
        }
        h3 {
            font-size: 1.5rem;
            font-family: $font-nanum;
            font-weight: normal;
            margin-bottom: 1.875rem;
        }
        p {
            &+p {
                margin-top: 1.875rem;
            }
        }
    }
    ._img {
        height: 100%;
        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
._admit {
    h2 {
        margin-bottom: 3.125rem;
    }
    ._txt {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                & + li {
                    margin-top: 2.5rem;
                }
            }
        }
    }
}
._simpleTextBlock {
    background: $color-blue;
    color: $color-white;
    ._max {
        width: 100%;
        margin: 0 auto;
        max-width: 42.81rem;
    }
    p {
        & + p {
            margin-top: 1.875rem;
        }
    }
    h2 {
        margin-bottom: 1.875rem;
    }
}
._txtBanner {
    background: $color-blue;
    padding: 4.06rem 0;
    h3 {
        font-size: 1.5rem;
        margin-bottom: 1.125rem;
        font-family: $font-nanum;
        font-weight: normal;
    }
}
._simpleBanner {
    padding: 5.31rem 0;
    background: $color-blue;
    color: $color-white;
    h1 {
        margin-bottom: 1.75rem;
        color: $color-white;
    }
    p {
        margin: 0;
        font-size: 0.875rem;
        & + p {
            margin-top: 1.25rem;
        }
    }
}
.pagination {
    li {
        font-size: 0.875rem;
        font-weight: bold;
        color: $color-grey-e;
        border: 0;
        a {
          color: $color-grey-e;
            border: 0;
            border-radius: 0 !important;
        }
    }
}
._postBanner {
    background: $color-blue;
    color: $color-white;
    ._txt {
        padding: 8.78vh 8.68vw;
        p {
            font-size: 0.875rem;
            margin: 0;
        }
        h2 {
            margin: 0;
            color: $color-white;
        }
    }
    ._img {
        height: 100%;
        img {
            height: 100%;
            object-position: center;
            object-fit: cover;
            width: 100%;
        }
    }
}
._share {
    margin-bottom: 5rem;
    h3 {
        font-weight: normal;
        font-family: $font-nanum;
        margin-bottom: 1.875rem;
    }
    ul {
        display: flex;
        list-style-type: none;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 auto;
        li {
            a {
                display: inline-block;
                background: $color-half-white;
                color: $color-grey-e;
                padding: 0.625rem 1.875rem 0.3125rem 1.875rem;
                &:hover {
                    background: $color-blue;
                    color: $color-white;
                }
            }
        }
    }
}
._nextPrev {
    border-top: 1px solid rgba(151, 151, 151, 0.2);
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    padding: 1.25rem 0;
    a {
        font-size: 0.875rem;
        font-weight: bold;
        text-transform: uppercase;
    }
}
._max750 {
    width: 100%;
    max-width: 46.87rem;
    margin: 0 auto;
}
._test {
    position: relative;
    background: $color-half-white;
    padding: 2.18rem 1.56rem 4.37rem 1.56rem;
    height: calc(100% - 1.875rem);
    margin-bottom: 1.875rem;
    ._author {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1.43rem;
        text-align: center;
        font-size: 0.875rem;
        font-weight: bold;
    }
}
._modal {
    .modal-content {
        position: relative;
        border: 0;
        border-radius: 0;
        .modal-body {
            padding: 0;
            ._test {
                background: none;
                padding-top: 3.43rem;
                margin-bottom: 0;
            }
        }
        .close {
            position: absolute;
            left: auto;
            right: 1.25rem;
            top: 1.25rem;
            z-index: 9;
        }
    }
}
.mb-60 {
    margin-bottom: 3.75rem;
}
._serviceBlock {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
}
._grounds {
    padding: 0;
    ._img {
        height: 100%;
        img {
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }
    ._txt {
        padding: 6.94vw 8.97vw 6.94vw 7.94vw;
        h3 {
            font-size: 1.5rem;
            font-family: $font-nanum;
            margin-bottom: 1.5rem;
        }
    }
}
._list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1.875rem 0;
    li {
        position: relative;
        padding-left: 1.43rem;
        line-height: 1.75;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0.5rem;
            width: 0.5rem;
            height: 0.5rem;
            @include border-50;
            background: $color-yellow;
        }
        & + li {
            margin-top: 0.625rem;
        }
    }
}
._cta2 {
    background: $color-light-blue;
    padding-top: 2.18rem;
    padding-bottom: 2rem;
    p {
        margin: 0;
        font-size: 1.125rem;
        font-weight: bold;
        color: $color-white;
        a {
            text-decoration: underline;
            color: $color-white;
            font-size: 1.125rem;
            font-weight: bold;
        }
    }
}
._toggle {
    border: 0;
    border-radius: 0;
    .card {
        border: 0;
        border-radius: 0;
        background: $color-half-white;
        & + .card {
            margin-top: 1.25rem;
        }
        .card-header {
            background: none;
            border: 0;
            padding: 0;
            a {
                font-size: 1rem;
                font-weight: bold;
                color: $color-grey-e;
                display: inline-block;
                width: 100%;
                position: relative;
                padding: 1.875rem 3.125rem 1.875rem 1.875rem;
                &:after {
                    content: "\f175";
                    font-family: FontAwesome;
                    position: absolute;
                    right: 1.875rem;
                    top: 50%;
                    @include translateY(-50%);
                }
                &[aria-expanded="true"] {
                    &:after {
                        content: "\f176";
                        font-family: FontAwesome;
                    }
                }
            }
        }
        .card-body {
            padding: 0 1.875rem 1.875rem 1.875rem;
        }
    }
}
._drop {
    overflow: hidden;
    &:after {
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9997;
        background: $color-grey-e;
    }
}
._popUp {
    background-size: cover !important;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    display: none;
    ._close {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
        z-index: 9;
        img {
            max-width: 1.62rem;
        }
    }
    ._img {
        height: 100%;
        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    ._txt {
        padding: 0 4.37rem;
        h2 {
            margin-bottom: 1.5rem;
        }
    }
    .___form {
        padding-right: 8.68vw;
        .gform_wrapper.gravity-theme input[type=color], .gform_wrapper.gravity-theme input[type=date], .gform_wrapper.gravity-theme input[type=datetime-local], .gform_wrapper.gravity-theme input[type=datetime], .gform_wrapper.gravity-theme input[type=email], .gform_wrapper.gravity-theme input[type=month], .gform_wrapper.gravity-theme input[type=number], .gform_wrapper.gravity-theme input[type=password], .gform_wrapper.gravity-theme input[type=search], .gform_wrapper.gravity-theme input[type=tel], .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=time], .gform_wrapper.gravity-theme input[type=url], .gform_wrapper.gravity-theme input[type=week], .gform_wrapper.gravity-theme select, .gform_wrapper.gravity-theme textarea {
            background: none;
            border: 1px solid $color-white;
            @include border(3px);
            color: $color-white;
            padding: 5px 10px;
            font-size: $font-14;
        }
        .gform_wrapper.gravity-theme .gform_fields {
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }
        .gform_wrapper.gravity-theme .gfield textarea.medium {
            height: 6rem;
        }
        ::-webkit-input-placeholder {
            color: $color-white;
        }
        ::-moz-placeholder {
            color: $color-white;
        }
        :-ms-input-placeholder {
            color: $color-white;
        }
        :-moz-placeholder {
            color: $color-white;
        }
    }
}
.admin-bar {
    ._popUp {
        top: 2rem;
    }
}
._form {
    .gform_wrapper.gravity-theme input[type=color], .gform_wrapper.gravity-theme input[type=date], .gform_wrapper.gravity-theme input[type=datetime-local], .gform_wrapper.gravity-theme input[type=datetime], .gform_wrapper.gravity-theme input[type=email], .gform_wrapper.gravity-theme input[type=month], .gform_wrapper.gravity-theme input[type=number], .gform_wrapper.gravity-theme input[type=password], .gform_wrapper.gravity-theme input[type=search], .gform_wrapper.gravity-theme input[type=tel], .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=time], .gform_wrapper.gravity-theme input[type=url], .gform_wrapper.gravity-theme input[type=week], .gform_wrapper.gravity-theme select, .gform_wrapper.gravity-theme textarea {
        border: 1px solid $color-grey-e;
        @include border(3px);
        background: none;
        color: rgba(74, 74, 74, 0.75);
        padding: 5px 10px;
    }
    .gform_wrapper.gravity-theme {
        .gform_fields {
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }
    }
    .btn {
        min-width: 9.06rem;
    }
}
._loader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $color-white;
    text-align: center;
    z-index: 9999;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    img {
        @include transition-d(1s);
        animation: zoom-in-zoom-out 2s ease-out infinite;
    }
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.3, 1.3);
    }
    100% {
        transform: scale(1, 1);
    }
}