$font-biryani: 'Biryani', sans-serif;
$font-nanum: 'Nanum Gothic', sans-serif;
$font-robot: 'Roboto', sans-serif;
$color-black: #000;
$color-grey-a: #4A4A4A;
$color-grey-e: #4E4E4E;
$color-white: #FFF;
$color-half-white: #F8F8F8;
$color-yellow: #F4B335;
$color-blue: #021D49;
$color-light-blue: #00369C;
$font-14: 0.875rem;
$font-16: 1rem;
$font-18: 1.125rem;
$font-20: 1.25rem;
$font-36: 2.25rem;
$font-48: 3rem;
@mixin transition {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
@mixin transition-d($value) {
  -webkit-transition: $value ease-in-out;
  -moz-transition: $value ease-in-out;
  -o-transition: $value ease-in-out;
  -ms-transition: $value ease-in-out;
  transition: $value ease-in-out;
}
@mixin border-50 {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
@mixin border($value) {
  border-radius: $value;
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
}
@mixin scale($value) {
  transform: scale($value);
  -webkit-transform: scale($value);
  -moz-transform: scale($value);
}
@mixin appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
}
@mixin translateX($value) {
  transform: translateX($value);
  -webkit-transform: translateX($value);
  -moz-transform: translateX($value);
}
@mixin translateY($value) {
  transform: translateY($value);
  -webkit-transform: translateY($value);
  -moz-transform: translateY($value);
}
@mixin translate($value) {
  transform: translate($value,$value);
  -webkit-transform: translate($value,$value);
  -moz-transform: translate($value,$value);
}
.color-white {
  color: #fff;
  * {
    color: #fff;
  }
}